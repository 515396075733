/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "enqueueRegionChange",
            "endpoint": "https://nobake41m7.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://xgdpg2l2wjaz7agwyaybgs6kxu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "us-east-1:f9b6d2a6-5055-4507-8735-ab6316d9a574",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KaAJ3rDuY",
    "aws_user_pools_web_client_id": "1prbiemm592o98hrkacnep5kac",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "kiyanaw-20231209160100-hostingbucket-production",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3n7kj622o8wnd.cloudfront.net",
    "aws_user_files_s3_bucket": "kiyanawb9b0a37496e34efd8156e27ad4220e33-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
